<template>
  <div class="reset-passwords-wrapper">
    <div class="permission-title-bar-wrap">
      <el-link target="_break" href="http://www.skysys.cn/">官方网站</el-link>
    </div>
    <form-container :logo="logo" message="忘记密码" :has-cut-icon="false" :custom-padding="20" :title-line-margin="21">
      <el-form ref="ResetPasswordForm" :model="userInfo" :rules="rules">
        <el-form-item label="手机号" prop="userMobile">
          <el-input v-model="userInfo.userMobile" />
        </el-form-item>
        <el-form-item label="验证码" prop="msgCode">
          <el-input v-model="userInfo.msgCode">
            <template slot="append">
              <SkyAppendButton :phone="userInfo.userMobile" template-type="common" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="newPassword">
          <el-input v-model="userInfo.newPassword" type="password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="verifyPassword">
          <el-input v-model="userInfo.verifyPassword" type="password" show-password />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit('ResetPasswordForm')"> 确 定 </el-button>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-link :underline="false">
            <SkyLink path="/" text="返回登录" />
          </el-link>
        </el-form-item>
      </el-form>
    </form-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FormContainer from '@/components/userContainer';
import statusInclude from '@/utils/statusCode';
import { setNewPassword } from '@/api/user';
import { validatePassword, validatePhone, validateVerificationCode } from '@/utils/validate.js';

export default {
  components: {
    FormContainer,
  },
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userInfo: {
        userMobile: '',
        newPassword: '',
        verifyPassword: '',
        msgCode: '',
      },
      rules: {
        userMobile: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        verifyPassword: [{ required: true, validator: validateConfirmPassword, trigger: 'blur' }],
        msgCode: [{ required: true, validator: validateVerificationCode, trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['logo', 'title']),
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = await setNewPassword(this.userInfo);
          if (!statusInclude(code)) return;
          this.$message.success(`${reason},5秒后返回到登录页`);
          setTimeout(() => {
            this.$router.push('/'); // 返回到登录页
          }, 5000);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-passwords-wrapper {
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/images/login/content_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /deep/.el-form {
    .el-button {
      background: #409eff;
      span {
        color: #fff;
      }
    }
  }
}
</style>
